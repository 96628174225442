import Api from "../../Api";
import csrf from "../../../csrf";
import {getChromeStyleId, getSubSeries} from "../../../utils/utils";
import {CRSO, FYU_DAMAGE_ANALYSIS} from "../../../utils/constants";
import cloneDeep from "lodash.clonedeep";

export const fetchEstimate = async (data, damage) => {
    return await Api.fetchEstimate(buildPricingOrEstimateRequest(data, damage));
}

export const fetchPricing = async (data, damage) => {
    return await Api.fetchPricing(buildPricingOrEstimateRequest(data, damage))
}

export const fetchChargeableOptions = async (suggestedDamage, account, consignment) => {
    let chargeable = 'Y';
    let description = 'Chargeable';
    const abortController = new AbortController();
    await Api.getChargeableOptions({
        itemCode: suggestedDamage.artCodes.itemCode || '',
        subItemCode: suggestedDamage.artCodes.subItemCode || '',
        damageCode: suggestedDamage.artCodes.damageCode || '',
        severityCode: suggestedDamage.artCodes.severityCode || '',
        groupCode: account.groupCode || '',
        category: consignment.categoryCode || '',
        signal: abortController.signal,
        listOptions: false,
    }).then(response => {
        if (!!response.code && !!response.description) {
            chargeable = response.code;
            description = response.description;
        }
    });
    return {chargeable, description};
}

export const fetchActions = async (suggestedDamage, account, consignment, workOrderInfo) => {
    let action = ''
    let actionCode = ''
    const abortController = new AbortController();
    await Api.getActions({
        itemCode: suggestedDamage.artCodes.itemCode || '',
        subItemCode: suggestedDamage.artCodes.subItemCode || '',
        damageCode: suggestedDamage.artCodes.damageCode || '',
        severityCode: suggestedDamage.artCodes.severityCode || '',
        groupCode: account.groupCode || '',
        category: consignment.categoryCode || '',
        auctionCode: workOrderInfo.auctionCode || '',
        signal: abortController.signal,
        listOptions: false,
    }).then(response => {
        const defaultAction = response.items?.find(i => i.action.hasOwnProperty('default') && !!i.action.default);
        if (!!defaultAction) {
            action = defaultAction.action.description;
            actionCode = defaultAction.action.code;
        }
    });
    return {action, actionCode};
};

const buildPricingOrEstimateRequest = (data, damage) => {
    let headers = csrf.getCSRFHeaders();
    headers['Content-Type'] = 'application/json';
    return {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            location: data.workOrderInfo.auctionCode,
            vin: data.unit?.vin,
            subSeries: getSubSeries(data.designatedDescription),
            chromeStyleId: getChromeStyleId(data.designatedDescription),
            groupCode: data.account.groupCode,
            accountNumber: data.consignment.manheimAccountNumber,
            categoryCode: data.consignment.categoryCode,
            consignmentHref: data.consignment.href,
            paintType: data.condition.paintType,
            damages: [damage]
        })
    };
}

export const buildDamage = (data, damageKey = null) => {
    return {
        damageKey,
        item: data.item,
        itemCode: data.itemCode,
        subItemCode: data.subItemCode,
        damageCode: data.damageCode,
        damage: data.damage,
        severityCode: data.severityCode,
        severity: data.severity,
        actionCode: data.actionCode,
        action: data.action,
        locationCode: data.locationCode,
        pricePlanIds: data.pricePlanIds,
        repairLaborHours: data.repairLaborHours,
        repairLaborCost: parseFloat(data.repairLaborCost),
        paintLaborHours: data.paintLaborHours,
        paintLaborCost: parseFloat(data.paintLaborCost),
        partLaborHours: data.partLaborHours,
        partLaborCost: parseFloat(data.partLaborCost),
        partCost: parseFloat(data.partCost),
        finalPartCost: parseFloat(data.finalPartCost)
    }
}

export const buildDamageFromSuggestedDamage = async (suggestedDamage, damageKey, chargeableOptions, actionOptions) => {
    return {
        item: suggestedDamage.artCodes.item,
        itemCode: suggestedDamage.artCodes.itemCode,
        subItemCode: suggestedDamage.artCodes.subItemCode,
        damage: suggestedDamage.artCodes.damage,
        damageCode: suggestedDamage.artCodes.damageCode,
        severity: suggestedDamage.artCodes.severity,
        severityCode: suggestedDamage.artCodes.severityCode,
        action: actionOptions.action,
        actionCode: actionOptions.actionCode,
        locationCode: suggestedDamage.artCodes.locationCode,
        damageKey: damageKey,
        hasImage: suggestedDamage.path,
        sourcePlatform: FYU_DAMAGE_ANALYSIS,
        chargeable: chargeableOptions.chargeable,
        chargeableDescription: chargeableOptions.description,
        panelId: suggestedDamage.artCodes.panelId
    };
};

export const getDamageKey = (data, includeL1 = true) => {
    let damageKey = `${data.itemCode}-${data.subItemCode}-${data.damageCode}`
    return includeL1 ? `L1-${damageKey}` : damageKey;
};

export const getOverlays = async (estimateResponse) => {
    const overlays = [];
    if (Array.isArray(estimateResponse?.account)) {
        if (estimateResponse.account.length === 1) {
            overlays.push('account');
        }
    }
    if (Array.isArray(estimateResponse?.model)) {
        if (estimateResponse.model.length === 1) {
            overlays.push('model');
        }
    }
    if (Array.isArray(estimateResponse?.mitchell)) {
        if (estimateResponse.mitchell.length === 1) {
            overlays.push('mitchell');
        }
    }
    return overlays;
}

export const getPricingTypes = async (estimateResponse) => {
    const pricingTypes = [];
    if (Array.isArray(estimateResponse?.account) && estimateResponse.account.length > 1) {
        let m = new Map();
        m.set('Account', estimateResponse.account);
        pricingTypes.push(m)
    }
    if (Array.isArray(estimateResponse?.model) && estimateResponse.model.length > 1) {
        let m = new Map();
        m.set('Model', estimateResponse.model);
        pricingTypes.push(m)
    }
    if (Array.isArray(estimateResponse?.mitchell) && estimateResponse.mitchell.length > 1) {
        let m = new Map();
        m.set('Mitchell', estimateResponse.mitchell);
        pricingTypes.push(m)
    }
    return pricingTypes;
}

export const getPricePlans = async (estimateResponse) => {
    const pricePlans = [];
    if (!!estimateResponse.account?.length && estimateResponse.account.length === 1) {
        pricePlans.push({pricingType: 'account', pricePlanId: estimateResponse.account[0].id})
    }
    if (!!estimateResponse.model?.length && estimateResponse.model.length === 1) {
        pricePlans.push({pricingType: 'model', pricePlanId: estimateResponse.model[0].id})
    }
    if (!!estimateResponse.mitchell?.length && estimateResponse.mitchell.length === 1) {
        pricePlans.push({pricingType: 'mitchell', pricePlanId: estimateResponse.mitchell[0].id})
    }
    return pricePlans;
}

export const damageDoesNotNeedPricing = async (value) => {
    return value === '' || value === 'NA' || value === 'IC';
}

export const getTotalCost = (repairLaborCost, paintLaborCost, partLaborCost, finalPartCost, partCost) => {
    repairLaborCost = !repairLaborCost ? 0 : repairLaborCost;
    paintLaborCost = !paintLaborCost ? 0 : paintLaborCost;
    partLaborCost = !partLaborCost ? 0 : partLaborCost;
    let totalCost = Number(repairLaborCost) + Number(paintLaborCost) + Number(partLaborCost);
    if (finalPartCost !== null && finalPartCost !== undefined) {
        totalCost += Number(finalPartCost);
    } else if (partCost !== null && partCost !== undefined) {
        totalCost += Number(partCost)
    }
    return totalCost;
}

export const getDamageImageRequired = (repairLaborCost, paintLaborCost, partLaborCost, finalPartCost, partCost, damageImageRequired) => {
    const costIsAtLeast200 = getTotalCost(repairLaborCost, paintLaborCost, partLaborCost, finalPartCost, partCost) >= 200.00;
    return damageImageRequired === 'Y' || costIsAtLeast200 ? 'Y' : 'N'
}

export const getFinalPricing = async (pricingResponse) => {
    let damage = pricingResponse[0];
    const partLaborHours = damage.partLaborHours;
    const partLaborCost = getFinalCost(damage.partLaborCost);
    const paintLaborHours = damage.paintLaborHours;
    const paintLaborCost = getFinalCost(damage.paintLaborCost);
    const repairLaborHours = damage.repairLaborHours;
    const repairLaborCost = getFinalCost(damage.repairLaborCost);
    const partDescription = damage.partNumber;
    const partCost = getFinalCost(damage.partCost);
    const finalPartCost = getFinalCost(damage.finalPartCost);
    const pricingDamageImageRequired = damage.damageImageRequired;
    const damageImageRequired = getDamageImageRequired(repairLaborCost, paintLaborCost, partLaborCost, finalPartCost, partCost, pricingDamageImageRequired);
    return {
        partLaborHours,
        partLaborCost,
        paintLaborHours,
        paintLaborCost,
        repairLaborHours,
        repairLaborCost,
        partDescription,
        partCost,
        finalPartCost,
        pricingDamageImageRequired,
        damageImageRequired
    };
}

export const getFinalCost = (cost) => {
    return (Math.round(cost * 100) / 100).toFixed(2);
}

export const pricingIsValid = (damagePricing) => {
    const pricingErrors =  getPricingErrors(isCRSO, damagePricing);
    return !pricingErrors.showPricingInputError && !pricingErrors.showEstimateError;
}

export const getPricingErrors = (isCRSO, damage) => {
    let pricingErrors = {
        showPricingInputError: false,
        showEstimateError: false
    }
    switch (true) {
        case !isCRSO:
        case !isNaN(damage.repairLaborCost) && parseFloat(damage.repairLaborCost) > 0 :
        case !isNaN(damage.paintLaborCost) && parseFloat(damage.paintLaborCost) > 0 :
        case !isNaN(damage.partLaborCost) && parseFloat(damage.partLaborCost) > 0 :
        case !isNaN(damage.partCost) && parseFloat(damage.partCost) > 0 :
        case !isNaN(damage.finalPartCost) && parseFloat(damage.finalPartCost) > 0 :
            return pricingErrors;
        default:
            pricingErrors.showPricingInputError = true
            return pricingErrors;
    }
}

export const getPricingTypesAndOverlays = async (estimateResponse) => {
    const overlays = await getOverlays(estimateResponse);
    const pricingTypes = await getPricingTypes(estimateResponse);
    return {overlays, pricingTypes};
}

export const isCRSO = (condition) => {
    return condition?.conditionType?.toUpperCase() === CRSO;
}

export const getDamageForManualPricingInput = (pricingField, data, damageKey) => {
    let damage = buildDamage(data, damageKey);
    if (pricingField === 'repairLaborHours') {
        damage.repairLaborCost = null;
    }
    if (pricingField === 'paintLaborHours') {
        damage.paintLaborCost = null;
    }
    if (pricingField === 'partLaborHours') {
        damage.partLaborCost = null;
    }
    if (pricingField === 'partCost') {
        damage.finalPartCost = null;
    }
    return damage;
}

export const mapDamagePricing = (damage, pricing) => {
    let finalDamage = cloneDeep(damage);
    finalDamage.partLaborHours = parseFloat(pricing.partLaborHours)
    finalDamage.partLaborCost = parseFloat(pricing.partLaborCost)
    finalDamage.paintLaborHours = parseFloat(pricing.paintLaborHours)
    finalDamage.paintLaborCost = parseFloat(pricing.paintLaborCost)
    finalDamage.repairLaborHours = parseFloat(pricing.repairLaborHours)
    finalDamage.repairLaborCost = parseFloat(pricing.repairLaborCost)
    finalDamage.partDescription = pricing.partDescription
    finalDamage.partCost = parseFloat(pricing.partCost)
    finalDamage.finalPartCost = parseFloat(pricing.finalPartCost)
    finalDamage.damageImageRequired = pricing.damageImageRequired
    return finalDamage;
}

export const isRentalReturnType = (damageEstimatorQuestions) => {
    return damageEstimatorQuestions?.estimatorType?.toLowerCase().startsWith('fordguide')
        || damageEstimatorQuestions?.estimatorType?.toLowerCase().startsWith('toyotaguide')
}

export const costFieldWasUpdated = (pricingField) => {
    return pricingField === 'repairLaborCost'
        || pricingField === 'paintLaborCost'
        || pricingField === 'partLaborCost'
        || pricingField === 'finalPartCost'
        || pricingField === 'partCost'
}

export const formatCurrency = (event) => {
    if (isNaN(event.target.value) || event.target.value === '') {
           return '0.00';
    } else {
        return parseFloat(event.target.value).toFixed(2);
    }
};

export const formatPricingVal = (valueToFormat) => {
    const value = parseFloat(valueToFormat);
    if (isNaN(value)) {
        return '';
    } else {
        return Math.round(value * 100) / 100;
    }
}