import {loadingWorkOrder, loadingWorkOrderComplete} from "./progressActions";
import {loadImageList} from "./damageImageActions";
import {getVehicleNotes} from "./f5NotesActions";
import Api from "../components/Api";
import {
    LOAD_WORK_ORDER_FAIL,
    LOAD_WORK_ORDER_SUCCESS,
    SHOW_LOCKED_RECORD_MODAL,
    SHOW_WORK_ORDER_ERROR_MODAL,
    UNLOAD_WORK_ORDER
} from "./dispatchTypes"
import {clearAllMessages} from "./statusMessageActions";
import {isNativeApp} from "../utils/utils";
import {calculateDamageKey} from "./conditionActions";
import {deepParseJson} from 'deep-parse-json'
import {OPENABLE_STATUSES} from "../utils/constants";

class ScanSubmitter {

    constructor(auctionCode, workOrderNumber, dispatch, getState) {
        this.workOrderNumber = workOrderNumber;
        this.auctionCode = isNativeApp() && auctionCode === undefined ? window.NativeApp.getLocationCode() : auctionCode;
        this.dispatch = dispatch;
        this.getState = getState;
        this.submit = this.submit.bind(this);
        this.abort = this.abort.bind(this);
    }

    async submit(timerId) {
        console.log("Scan submitted for Auction: ", this.auctionCode, " and WO: ", this.workOrderNumber);
        this.timerId = timerId;

        clearAllMessages()(this.dispatch);
        delete this.workOrderApiError;
        this.abortController = new AbortController();
        this.signal = this.abortController.signal;
        loadingWorkOrder()(this.dispatch);

        if (isNativeApp() && this.getState().appFlags.flags && this.getState().appFlags.flags.deviceStorage) {
            let state = window.NativeApp.getVehicleState(this.workOrderNumber, this.getState().appFlags.reduxStateVersion);
            if (state) {
                this.auctionCode = window.NativeApp.getLocationCode();
                let data = deepParseJson(state);
                let consignment = deepParseJson(data.consignment);

                if (consignment && consignment.href) {
                    let stagedInspectResponse = await Api.getWorkOrderLatestStatus({consignment: consignment});
                    if (stagedInspectResponse.href) {
                        let item = stagedInspectResponse.items?.[0];
                        if (item && !OPENABLE_STATUSES.includes(item.status?.overAllStatus)) {
                            this.dispatch({
                                type: SHOW_LOCKED_RECORD_MODAL
                            });
                            loadingWorkOrderComplete()(this.dispatch);
                            return;
                        }
                    }
                }
                this.dispatch({type: 'hydrate', payload: deepParseJson(data)});
                loadingWorkOrderComplete()(this.dispatch);
                return;
            }
        }
        await this.getWorkOrder();
        await this.processWorkOrderResponse();
        loadingWorkOrderComplete()(this.dispatch);
    }

    async submitForL1(timerId) {
        console.log("Fetching L1 inspection for Auction: ", this.auctionCode, " and WO: ", this.workOrderNumber);
        this.timerId = timerId;
        clearAllMessages()(this.dispatch);
        delete this.workOrderApiError;
        this.abortController = new AbortController();
        this.signal = this.abortController.signal;
        loadingWorkOrder()(this.dispatch);

        await this.getL1WorkOrder();

        await this.processWorkOrderResponse();

        loadingWorkOrderComplete()(this.dispatch);
    }


    async abort() {
        this.abortController.abort();
        this.workOrderApiError = 'Request cancelled by user.';
        await this.processWorkOrderFail();
        loadingWorkOrderComplete()(this.dispatch);
    }

    async getWorkOrder() {
        try {
            const params = {locationCode: this.auctionCode, workOrderNumber: this.workOrderNumber, signal: this.signal};
            this.workOrderResponse = await Api.fetchWorkOrder(params);
        } catch {
            clearTimeout(this.timerId);
            if (this.signal.aborted) {
                this.workOrderApiError = 'Request cancelled by user.';
            } else {
                this.workOrderApiError = 'Error retrieving vehicle information.';
            }
        }
        Api.notifyAjaxRequestFinished();
    }

    async getL1WorkOrder() {
        try {
            const params = {
                locationCode: this.auctionCode,
                workOrderNumber: this.workOrderNumber,
                signal: this.signal,
                inspectionType: 'L1'
            };
            this.workOrderResponse = await Api.fetchWorkOrderByInspectionType(params);
        } catch {
            clearTimeout(this.timerId);
            if (this.signal.aborted) {
                this.workOrderApiError = 'Request cancelled by user.';
            } else {
                this.workOrderApiError = 'Error retrieving vehicle information.';
            }
        }
        Api.notifyAjaxRequestFinished();
    }

    async processWorkOrderResponse() {
        this.haveValidWorkOrderResponse() ? await this.processWorkOrderSuccess() : await this.processWorkOrderFail();
    }

    async processWorkOrderSuccess() {
        isNativeApp() && window.NativeApp.setCurrentWorkorderAndLocation(this.workOrderNumber, this.auctionCode);
        await getVehicleNotes(this.workOrderNumber, this.auctionCode, this.signal)(this.dispatch, this.getState);
        await this.formatDamageKeyAsync();
        await loadImageList(this.workOrderResponse.condition)(this.dispatch);
        this.dispatchWorkOrderSuccess();
    }

    async processWorkOrderFail() {
        clearTimeout(this.timerId);
        if (!!this.workOrderResponse) {
            const logName = `workOrderResponse-${this.auctionCode}-${this.workOrderNumber}`
            console.debug(`ScanSubmitter->processWorkOrderFail->${logName}->status: ${this.workOrderResponse.status}`)
        } else {
            const logLine = `There was no workOrderResponse for: ${this.auctionCode}-${this.workOrderNumber}`
            console.debug(`ScanSubmitter->processWorkOrderFail->${logLine}`)
        }
        if (this.workOrderResponse && this.workOrderResponse.status === 400) {
            this.dispatch({
                type: SHOW_WORK_ORDER_ERROR_MODAL,
                message: await this.workOrderErrorMessage()
            });
        } else {
            this.dispatch({
                type: LOAD_WORK_ORDER_FAIL,
                message: await this.workOrderErrorMessage()
            });
            this.dispatch({
                type: UNLOAD_WORK_ORDER
            });
        }
    }

    async formatDamageKeyAsync() {
        await this.formatDamageKey(this.workOrderResponse.condition)
        await this.formatDamageKey(this.workOrderResponse.repurpose)
    }

    formatDamageKey(condition) {
        if (condition && condition.damages && condition.damages.length) {
            condition.damages.map(damage => {
                damage.damageKey = calculateDamageKey(damage);
                return damage;
            });
        }
    }

    haveValidWorkOrderResponse() {
        console.log("RESPONSE", this.workOrderResponse);
        return this.workOrderResponse && !this.workOrderResponse.status
    }

    async workOrderErrorMessage() {
        return this.workOrderApiError || (await this.workOrderResponse.json());
    }

    dispatchWorkOrderSuccess() {
        this.dispatch({
            type: LOAD_WORK_ORDER_SUCCESS,
            payload: this.workOrderResponse,
            workOrderNumber: this.workOrderNumber,
            auctionCode: this.auctionCode
        });
    }
}

export {ScanSubmitter};