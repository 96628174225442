import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import Button from "@prism/button";
import {addSuggestedDamage} from "../../../actions/SuggestedDamageActions";
import {addDamage} from "../../../actions/conditionActions";
import {showDamageSuccessMessage} from "../../../actions/globalDisplayActions";
import {addSuggestedDamageImage} from "../../../actions/damageImageActions";
import {clearSelectedFlatCarPanel} from "../../../actions/flatCarActions";
import * as pricingUtils from "./PricingUtils";
import {formatPricingVal, isRentalReturnType, pricingIsValid} from "./PricingUtils";
import SlidePanel from "../../SlidePanel";
import Pricing from "../Pricing";
import DamageCalculator from "./DamageCalculator/DamageCalculator";
import Api from "../../Api";
import csrf from "../../../csrf";
import {DamagePricingInputs} from "../../../utils/DamagePricingInputs";
import Message from "@prism/message";
import {MISSING_ESTIMATE_MESSAGE, MISSING_PRICING_MESSAGE} from "../../../utils/constants";
import Col from "@prism/col";
import Row from "@prism/row";
import TextCounter from "../../common/TextCounter";

const AddSuggestedDamageButton = (props) => {
    const {
        account,
        addDamage,
        addSuggestedDamage,
        addSuggestedDamageImage,
        consignment,
        suggestedDamage,
        showDamageSuccessMessage,
        workOrderInfo,
        clearSelectedFlatCarPanel,
        panelSuggestedDamages,
        setApiLoading,
        isCRSO,
        damageRules
    } = props;

    const isFordOrToyota = () => {
        return isFord() || isToyota();
    }
    const isFord = () => {
        return damageRules.directReturnCode === 'Y' && consignment.manufactureCode === "FOR";
    }
    const isToyota = () => {
        return (consignment.categoryCode === "TRP" && consignment.manufactureCode === "TOY");
    }

    const [showPricing, setShowPricing] = useState(false);
    const [pricingTypesList, setPricingTypesList] = useState([]);
    const [dmg, setDmg] = useState({});
    const [showCalculator, setShowCalculator] = useState(false);
    const [damageEstimatorQuestions, setDamageEstimatorQuestions] = useState([]);
    const [showTurnInCalculator, setShowTurnInCalculator] = useState(isFordOrToyota());
    const [showPricingInput, setShowPricingInput] = useState(false);
    const [showPricingInputError, setShowPricingInputError] = useState(true);
    const [validationFailed, setValidationFailed] = useState(false);
    const [showEstimateError, setShowEstimateError] = useState(false);
    const dmgRef = useRef(dmg);


    useEffect(() => {
        dmgRef.current = dmg;
        setShowPricingInputError(!pricingIsValid(dmg));
        setShowEstimateError(false);
    }, [dmg]);

    useEffect(() => {
        if (pricingTypesList.length !== 0) {
            setShowPricing(true)
        }
    }, [pricingTypesList]);

    function getDamageKey() {
        return suggestedDamage.newDamageKey ? suggestedDamage.newDamageKey : suggestedDamage.artCodes.damageKey;
    }

    const handleAddSuggestedDamage = async () => {
        setApiLoading(true)
        const chargeableOptions = await pricingUtils.fetchChargeableOptions(suggestedDamage, account, consignment);
        const actionOptions = await pricingUtils.fetchActions(suggestedDamage, account, consignment, workOrderInfo);
        setApiLoading(false)
        let damage = await pricingUtils.buildDamageFromSuggestedDamage(suggestedDamage, getDamageKey(), chargeableOptions, actionOptions);
        if (isCRSO) {
            setDmg(damage)
        } else {
            addSuggestedDamage({...suggestedDamage, isEdited: props.isEdited});
            addDamage(damage);
            addImageAndAfterAddCleanUp();
        }
    }

    const handlePricingAndAddDamage = async (damage) => {
        let estimateResponse;
        try {
            estimateResponse = await getEstimate(damage);
        } catch (e) {
            return;
        }
        const {overlays, pricingTypes} = await pricingUtils.getPricingTypesAndOverlays(estimateResponse)
        const estimatorQuestions = await fetchDamageEstimatorQuestions(damage);
        await updateDamageEstimatorQuestions(estimatorQuestions);
        if (!!estimateResponse && Object.keys(estimateResponse).length !== 0) {
            setPricingTypesList(pricingTypes);
            damage.pricePlanIds = await pricingUtils.getPricePlans(estimateResponse);
            const callPricing = overlays.length !== 0 && pricingTypes?.length === 0;
            if (!estimatorQuestions?.questions?.length && callPricing) {
                await validatePricingAndAddDamage(damage);
            }
        } else if (!estimatorQuestions?.questions?.length) {
            console.log("Empty estimate and questions response, opening manual inputs")
            resetPricingValuesForDisplay()
            setShowPricingInput(true);
            setShowPricingInputError(true);
            setShowEstimateError(true);
        }
    }

    useEffect(() => {
        const changeOfAction = async () => {
            await handlePricingAndAddDamage(dmgRef.current);
        }
        if (dmg.actionCode !== undefined) {
            changeOfAction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dmg.actionCode]);


    const validatePricingAndAddDamage = async (damage) => {
        const pricingResponse = await getPricing(damage);
        const finalPricing = await getFinalPricing(pricingResponse);
        if (pricingIsValid(finalPricing)) {
            handleAddDamage(damage, finalPricing);
        } else {
            resetPricingValuesForDisplay()
            setShowPricingInput(true);
            setShowPricingInputError(true);
            setShowEstimateError(true);
        }
    }

    const handleAddDamage = (damage, finalPricing) => {
        addSuggestedDamage({...suggestedDamage, isEdited: props.isEdited});
        addDamage(pricingUtils.mapDamagePricing(damage, finalPricing));
        addImageAndAfterAddCleanUp();
    }

    const handleSaveDamage = () => {
        addSuggestedDamage({...suggestedDamage, isEdited: props.isEdited});
        addDamage(pricingUtils.mapDamagePricing(dmg, dmg))
        addImageAndAfterAddCleanUp();
    }

    const addImageAndAfterAddCleanUp = () => {
        if (!!suggestedDamage.path) {
            addSuggestedDamageImage(getDamageKey(), suggestedDamage.path)
        }
        showDamageSuccessMessage();
        if (!panelSuggestedDamages()) clearSelectedFlatCarPanel();
    }


    const getEstimate = async (damage) => {
        setApiLoading(true);
        let estimateResponse;
        try {
            estimateResponse = await pricingUtils.fetchEstimate(props, damage);
            setApiLoading(false);
        } catch (e) {
            setApiLoading(false);
            console.log("Estimate call failed")
        }
        return estimateResponse;
    }

    const getPricing = async (damage) => {
        let response;
        try {
            setApiLoading(true);
            response = await pricingUtils.fetchPricing(props, damage);
            setApiLoading(false);
        } catch (e) {
            setApiLoading(false);
            console.log("Pricing call failed")
            resetPricingValuesForDisplay()
            setShowPricingInputError(true);
            setShowPricingInput(true);
            setShowEstimateError(true);
            return e;
        }
        return response;
    }

    async function getFinalPricing(pricingResponse) {
        let finalPricing = {};
        if (Array.isArray(pricingResponse) && !!pricingResponse.length) {
            finalPricing = await pricingUtils.getFinalPricing(pricingResponse)
        }
        return finalPricing;
    }

    const handlePricingUpdate = async (damage) => {
        const pricingResponse = await getPricing(damage);
        const finalPricing = await getFinalPricing(pricingResponse);
        if (pricingIsValid(finalPricing)) {
            if (!showCalculator) {
                handleAddDamage(damage, finalPricing);
            } else {
                setDmg(pricingUtils.mapDamagePricing(damage, finalPricing));
            }
        }
    };

    function updateDamageValue(name, value) {
        setDmg(prevDamage => ({
            ...prevDamage,
            [name]: value
        }));
    }

    const handlePriceChange = (event) => {
        updateDamageValue(event?.target?.name, formatPricingVal(event?.target?.value));
    }


    const handleUpdatePricing = async (event) => {
        const pricingField = event.target.name;
        const damageKey = pricingUtils.getDamageKey(dmg, false)
        const damage = pricingUtils.getDamageForManualPricingInput(pricingField, dmg, damageKey);
        const pricingResponse = await getPricing(damage);
        const finalPricing = await getFinalPricing(pricingResponse);
        if (await pricingIsValid(finalPricing)) {
            setDmg(pricingUtils.mapDamagePricing(dmg, finalPricing));
        } else {
            setShowPricingInputError(true);
        }
    };

    const handleManualUpdatePricing = (event) => {
        if (pricingUtils.costFieldWasUpdated(event.target.name)) {
            updateDamageValue(event?.target?.name, pricingUtils.formatCurrency(event));
        }
    }

    const resetPricingValuesForDisplay = () => {
        setDmg(prevDamage => ({
            ...prevDamage,
            repairLaborCost: '0.00',
            repairLaborHours: 0.0,
            paintLaborCost: '0.00',
            paintLaborHours: 0.0,
            partLaborCost: '0.00',
            partLaborHours: 0.0,
            partCost: '0.00',
            finalPartCost: '0.00',
            pricePlanIds: []
        }))
    };

    const handleNotesChange = (event) => {
        setDmg(prevDamage => ({
            ...prevDamage,
            notes: event.value,
        }));
    };
    const handleEditPartDescriptionChange = (event) => {
        setDmg(prevDamage => ({
            ...prevDamage,
            partDescription: event.value,
        }));
    };

    const cancelPricingOverlay = async () => {
        if (!showCalculator) {
            setDmg({})
        }
        setShowPricing(false);
    };

    const hidePricingOverlay = async () => {
        setShowPricing(false);
    };

    const isDisabled = useMemo(() => {
        return !suggestedDamage.artCodes.damage ||
            !suggestedDamage.artCodes.damageCode ||
            !suggestedDamage.artCodes.severity ||
            !suggestedDamage.artCodes.severityCode;
    }, [suggestedDamage]);

    const updateDamageEstimatorQuestions = async (response) => {
        if (!!response?.questions?.length) {
            setDamageEstimatorQuestions(response);
            setShowCalculator(true);
        } else {
            setShowCalculator(false);
            setShowTurnInCalculator(false);
            console.log("Empty estimate questions response")
        }
    }

    const fetchDamageEstimatorQuestions = async (damage) => {
        setApiLoading(true);
        const questions = await Api.getDamageEstimatorQuestions(buildQuestionParams(damage));
        setApiLoading(false);
        return questions;
    }

    const getDirectReturnCode = () => {
        return damageRules.directReturnCode === 'Y' ? 'YES' : damageRules.directReturnCode;
    }

    const buildQuestionParams = (damage) => {
        let headers = csrf.getCSRFHeaders();
        headers['Content-Type'] = 'application/json';
        let params = {
            itemCode: damage.itemCode,
            subItemCode: damage.subItemCode,
            damageCode: damage.damageCode,
            severityCode: damage.severityCode,
            headers: headers
        }
        if (isFord() && showTurnInCalculator) {
            params.manufactureCode = consignment.manufactureCode
            params.directTurnIn = getDirectReturnCode()
        } else if (isToyota() && showTurnInCalculator) {
            params.manufactureCode = consignment.manufactureCode
            params.categoryCode = consignment.categoryCode
        } else {
            params.actionCode = damage.actionCode
            params.chargeable = damage.chargeable
        }
        return params;

    }

    const hideDamageCalculator = () => {
        setShowCalculator(false);
        setDmg({})
    }

    const handleActionChange = async ({target: {options, selectedIndex, value}}) => {
        if (!!value) {
            setDmg(prevDamage => ({
                ...prevDamage,
                action: options[selectedIndex].text,
                actionCode: value,
            }));
        }
        setShowCalculator(false);
        setDamageEstimatorQuestions([]);
    }

    const handleChargeableChange = async ({target: {options, selectedIndex, value}}) => {
        setDmg(prevDamage => ({
            ...prevDamage,
            chargeable: value,
            chargeableDescription: options[selectedIndex].text,
        }));
    };

    const updatePricingForDamage = async ({repairLaborCost, repairLaborHours, aluminum}) => {
        let pricingRequestDamage = {};
        if (repairLaborCost) {
            pricingRequestDamage = {
                ...dmg,
                repairLaborCost: repairLaborCost,
                aluminum: aluminum
            }
        } else if (repairLaborHours) {
            pricingRequestDamage = {
                ...dmg,
                repairLaborCost: null,
                repairLaborHours: repairLaborHours,
                aluminum: aluminum
            }
        }
        setShowCalculator(false);
        await validatePricingAndAddDamage(pricingRequestDamage);
    };

    const resetTurnInCalculatorFlag = () => {
        setShowTurnInCalculator(false);
    };

    const handleCancelClick = () => {
        setShowPricingInput(false);
        setDmg({})
    }

    const handleInputValidation = (data) => {
        setValidationFailed(data?.validationFailed);
    }

    const saveButtonDisabled = () => {
        return showPricingInputError || validationFailed;
    }

    return (
        <>
            {showCalculator &&
                <SlidePanel isOpen={showCalculator} width={"100vw"} showHeader={true}
                            contentClassName="bg-white" from="left" zIndex={4001}>
                    <DamageCalculator hideDamageCalculator={hideDamageCalculator}
                                      id="damage-calculator"
                                      damageEstimatorQuestions={damageEstimatorQuestions}
                                      isRentalReturnType={isRentalReturnType(damageEstimatorQuestions)}
                                      manufactureCode={consignment.manufactureCode}
                                      directReturnCode={getDirectReturnCode()}
                                      categoryCode={consignment.categoryCode}
                                      damage={pricingUtils.buildDamage(dmg)}
                                      updatePricingForDamage={updatePricingForDamage}
                                      resetTurnInCalculatorFlag={resetTurnInCalculatorFlag}
                                      updateAction={handleActionChange}
                                      updateChargable={handleChargeableChange}
                    />
                </SlidePanel>}
            {showPricing && <SlidePanel
                isOpen={showPricing}
                width={"100vw"}
                showHeader={true}
                contentClassName="bg-white"
                from="right"
                zIndex={4001}>
                <Pricing
                    hidePricingOverlay={hidePricingOverlay}
                    id="pricing-overlay"
                    damage={dmg}
                    pricingTypes={pricingTypesList}
                    handlePricingUpdate={handlePricingUpdate}
                    cancelPricingOverlay={cancelPricingOverlay}
                />
            </SlidePanel>}
            {showPricingInput && <SlidePanel isOpen={showPricingInput} width={"100vw"} showHeader={true}
                                             contentClassName="bg-white" from="left" zIndex={4001}>
                <Col xs={12}>
                    <DamagePricingInputs
                        data={dmg}
                        handleUpdatePricing={handleUpdatePricing}
                        handleManualUpdatePricing={handleManualUpdatePricing}
                        priceChange={handlePriceChange}
                        handleInputValidation={handleInputValidation}
                    />
                    <Row>
                        <Col className="font-weight-bold px-0 flex-grow-1">
                            <TextCounter
                                label="Part Number/Description"
                                rows="1"
                                id='part-description'
                                onChange={handleEditPartDescriptionChange}
                                value={dmg.partDescription}
                                maxLength={30}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-12 font-weight-bold px-0">
                            <TextCounter
                                id="damage-comments"
                                onChange={handleNotesChange}
                                placeholder="Enter Comments"
                                label="Damage Comments"
                                maxLength={30}
                                rows="1"
                                value={dmg.notes}
                            />
                        </Col>
                    </Row>

                    {showPricingInputError ?
                        <Message id="missing-prices-message" color="danger"><i
                            className="icon prism-icon-notification-circle" color="danger"/>
                            {showEstimateError ? MISSING_ESTIMATE_MESSAGE : showPricingInputError ? MISSING_PRICING_MESSAGE : ''}
                        </Message> : <></>}
                    <Row className={"my-4"}>
                        <Col xs={6}>
                            <Button
                                className="w-100"
                                outline
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button
                                className="w-100"
                                color="secondary"
                                disabled={saveButtonDisabled()}
                                onClick={handleSaveDamage}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Col>

            </SlidePanel>}
            <Button id={'add-suggested-' + suggestedDamage.artCodes.damageKey}
                    onClick={handleAddSuggestedDamage}
                    disabled={isDisabled}
                    className={"mx-1 my-0"}
                    color="secondary">
                <i className={"icon prism-icon-checkmark p-1 " + (isDisabled ? 'text-light-gray' : 'text-white')}/>
            </Button>
        </>
    );
};

const mapDispatchToProps = {
    addDamage,
    addSuggestedDamage,
    addSuggestedDamageImage,
    showDamageSuccessMessage,
    clearSelectedFlatCarPanel
};

function mapStateToProps({account, condition, consignment, unit, workOrderInfo, damageRules, designatedDescription}) {
    return {account, condition, consignment, unit, workOrderInfo, damageRules, designatedDescription};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSuggestedDamageButton);
