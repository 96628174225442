import {
    ADD_SUGGESTED_DAMAGE_IMAGE,
    DELETE_DAMAGE_IMAGE,
    HANDLE_KEEP_PHOTO_RESPONSE,
    SET_DAMAGE_IMAGES,
    UPDATE_DAMAGE_IMAGE
} from './dispatchTypes';
import {DELETED, USE_EXISTING} from "../utils/constants";
import {calculateDamageKey} from "./conditionActions";


export const loadImageList = (condition) => async (dispatch) => {
    let damageImages = [];
    if (condition?.damages) {
        damageImages = condition.damages.map(damage => {
            if (damage.imageHref) {
                if (condition.copied || condition.repurposed === "Y") {
                    return {
                        url: damage.imageHref,
                        key: calculateDamageKey(damage),
                        previousKey: calculateDamageKey(damage),
                        status: USE_EXISTING
                    }
                } else {
                    return {
                        url: damage.imageHref,
                        key: damage.damageKey
                    }
                }
            } else {
                return null
            }
        });
    }
    setDamageImages(damageImages.filter(n => n))(dispatch);
};

export const setDamageImages = (damageImages) => dispatch => {
    console.debug("setting damage images to... ", damageImages);
    dispatch({
        type: SET_DAMAGE_IMAGES,
        damageImages
    });
};

export const deleteDamageImage = (damageKey) => dispatch => {
    dispatch({
        type: DELETE_DAMAGE_IMAGE,
        payload: {key: damageKey}
    })
};

export const hasActiveImage = (damageKey) => (dispatch, getState) => {
    if (!damageKey || !getState().damageImages) {
        return false
    }
    const damageImages = getState().damageImages;
    const damageImage = damageImages.find(img => img.status !== DELETED && img.key === damageKey);
    return !!damageImage
};

export const updateDamageImage = (damageKey, imageHref, ...args) => (dispatch) => {
    let originalDamageKey;
    let keepPrevPhoto;
    let damageKeyAtWorkOrderLoad;
    let deletedImageAfterKeeping;
    if (args.length > 0) {
        originalDamageKey = args[0].originalDamageKey
        keepPrevPhoto = args[0].keepPrevPhoto
        damageKeyAtWorkOrderLoad = args[0].damageKeyAtWorkOrderLoad
        deletedImageAfterKeeping = args[0].deletedImageAfterKeeping
        dispatch({
            type: HANDLE_KEEP_PHOTO_RESPONSE,
            payload: {
                key: damageKey,
                url: imageHref,
                keepPrevPhoto,
                originalDamageKey,
                damageKeyAtWorkOrderLoad,
                deletedImageAfterKeeping
            }
        })
    } else {
        dispatch({
            type: UPDATE_DAMAGE_IMAGE,
            payload: {
                key: damageKey,
                url: imageHref
            }
        })
    }
};

export const getActiveImage = (damageKey) => (dispatch, getState) => {
    const damageImages = getState().damageImages;
    const filteredImage = damageImages.find(damageImage => damageImage.status !== DELETED && damageImage.key === damageKey);
    if (!!filteredImage) {
        return filteredImage.url
    }
    return undefined;
};

export const getActiveImageObject = (damageKey) => (dispatch, getState) => {
    const damageImages = getState().damageImages;
    return damageImages.find(damageImage => damageImage.status !== DELETED && damageImage.key === damageKey);
};

export const addSuggestedDamageImage = (damageKey, imageHref) => dispatch => {
    dispatch({
        type: ADD_SUGGESTED_DAMAGE_IMAGE,
        payload: {key: damageKey, url: imageHref}
    })
}

