import {CONDITION_ANNOUNCEMENT, ODOMETER_INOP_ANNOUNCEMENT_CODE} from "./constants";
import {DAMAGE_AREAS} from "./constantsDamages";
import {REQUIRED_PHOTO_ITEMS} from "./requiredPhotoItems";
import {STRUCTURAL_DAMAGE_ITEMS} from "./structuralDamageItems";

const uuid = a =>
    a
        ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
        : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
export {uuid};

export const sortDamagesByArea = (damages, isSuggestedDamage = false) => {
    let sortedDamages = [];
    const damagesWithoutLocations = []
    DAMAGE_AREAS.forEach(damageArea => {
        let filteredDamages = damages.filter(damage => {
            if (isSuggestedDamage) {
                return damage.artCodes.locationCode === damageArea.code;
            } else {
                return damage.locationCode === damageArea.code;
            }
        });
        sortedDamages = sortedDamages.concat(filteredDamages.sort((a, b) => {
            return a.sequenceNumber - b.sequenceNumber
        }));
    });
    damages.forEach(damage => {
        if (!sortedDamages.includes(damage)) {
            damagesWithoutLocations.push(damage)
        }
    })
    return sortedDamages.concat(damagesWithoutLocations);
};

export const getCurrentDate = (separator = '') => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
};

export const isNativeApp = () => {
    return typeof (window.NativeApp) !== 'undefined'
};

export const emojiCheck = (text) => {
    const regex = /^$|[\w\x20-\x7F@.#&+-]+$/g;
    return text.match(regex);
}

export const isDuplicateDamageKey = (currentDamageKey, newDamageKey) => {
    let scrubDamageKey = (key) => {
        let l = 0;
        let count = 0;
        while (l < key.length && count < 4) {
            if (key[l] === '-') count++;
            l++
        }
        return key.substring(3, l);
    };
    return scrubDamageKey(currentDamageKey) === scrubDamageKey(newDamageKey);
}

export const odometerInopAnnouncementExists = (announcementsList) => {
    let conditionAnnouncements = announcementsList.items?.find(announcements => announcements.source === CONDITION_ANNOUNCEMENT);
    let odoInopAnnouncement = conditionAnnouncements?.announcements.find(announcement => announcement.referenceCode === ODOMETER_INOP_ANNOUNCEMENT_CODE)
    return !!odoInopAnnouncement;
}

export const getChromeStyleId = (designatedDescription) => {
    let styleId;
    const chromeIdentifier = designatedDescription?.thirdPartyIdentifiers?.chrome;
    if (chromeIdentifier) {
        styleId = chromeIdentifier[0].styleId;
    }
    return styleId ? styleId : 0;
}

export const getSubSeries = (designatedDescription) => {
    const manheimIdentifier = designatedDescription?.thirdPartyIdentifiers?.manheim?.[0];
    return (manheimIdentifier && manheimIdentifier.ssubse !== null && manheimIdentifier.ssubse !== undefined) ? manheimIdentifier.ssubse : '';
}

export const isOemColor = (color) => {
    if (!!color) {
        return (!!color.oemOptionCode) || (!!color.oemName);
    }
    return false;
}

export const hasOemOptionCode = (color) => {
    return color.oemOptionCode !== null && color.oemOptionCode !== undefined
}

export const isAuditMode = () => {
    return isNativeApp() && window.NativeApp.getAuditMode();
}

export const isPricingEmptyWithValidAction = (damage) => {
    return damage.repairLaborCost <= 0 && damage.paintLaborCost <= 0 && damage.partLaborCost <= 0 && damage.partCost <= 0 &&
        damage.finalPartCost <= 0 && damage.actionCode !== 'NA' && damage.actionCode !== 'IC';
}

export const damageRequiresPhoto = (longDamageKey) => {
    let damageKey = longDamageKey.split("-").slice(0, 4).join("-");
    return REQUIRED_PHOTO_ITEMS.includes(damageKey) || STRUCTURAL_DAMAGE_ITEMS.includes(damageKey);
}

export const isAutomatedDamage = (autoDamages, damageKey) => {
    return !!(autoDamages.length && autoDamages.find((autoDamage) => autoDamage.damageKey === damageKey));
}
